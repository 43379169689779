import { Poi } from '../../../features/fetchData/mappers/mapPoi';
import { Geofence } from '../../../features/fetchData/mappers/mapGeofence';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FetchPoisSuccessActionPayload = Poi[];
export type FetchGeofencesSuccessActionPayload = Geofence[];

export interface DataState {
    isGeoBooked: boolean;
    fetchPoisFailed?: boolean;
    fetchGeofencesFailed?: boolean;
    pois: Poi[];
    geofences: Geofence[];
}

export const defaultDataState: DataState = {
    isGeoBooked: true,
    fetchPoisFailed: undefined,
    fetchGeofencesFailed: undefined,
    pois: [],
    geofences: [],
};

const { reducer, actions } = createSlice({
    name: 'data',
    initialState: defaultDataState,
    reducers: {
        geoBookedChanged: (state: DataState, action: PayloadAction<boolean>) => {
            state.isGeoBooked = action.payload;
        },
        fetchGeoBookedFailed: (state: DataState) => {
            // do nothing
            // TODO Why do we even have this action?
        },
        fetchPoisSuccess: (state: DataState, action: PayloadAction<FetchPoisSuccessActionPayload>) => {
            state.pois = action.payload;
            state.fetchPoisFailed = false;
        },
        fetchPoisFailed: (state: DataState) => {
            state.fetchPoisFailed = true;
        },
        fetchGeofencesSuccess: (state: DataState, action: PayloadAction<FetchGeofencesSuccessActionPayload>) => {
            state.geofences = action.payload;
            state.fetchGeofencesFailed = false;
        },
        fetchGeofencesFailed: (state: DataState) => {
            state.fetchGeofencesFailed = true;
        },
    },
});

export { reducer as dataReducer, actions as dataActions };
