import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';

import {
    DEFAULT_LOCALE,
    DisplayMessagesInterface,
    getDisplayMessages,
    getLocale,
    getUserAccount,
} from '../../configuration';
import { useEffectOnce } from './hooks';
import { fetchGeoBooked } from '../fetchData/fetchGeoBooked';
import { RoutePlannerContainer } from './RoutePlanner';
import { MessageHandlerContainer, sendMessage } from '../livemonitorCommunication/MessageHandler';
import { eventFactory } from '../livemonitorCommunication/eventFactory';
import { AppDispatch, RootState } from '../../configuration/setup/store';

interface RoutePlanningProps {
    accountId: string;
    displayMessages: DisplayMessagesInterface;
    loadGeoBooked: () => {};
    userLocale: string;
}

const App = (props: RoutePlanningProps): JSX.Element | null => {
    const { displayMessages, loadGeoBooked, userLocale } = props;

    useEffectOnce(() => {
        sendMessage(eventFactory.widgetLoaded());
        loadGeoBooked();
    });
    if (!displayMessages) {
        return null;
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <MessageHandlerContainer />
            <ApplicationLayout>
                <RoutePlannerContainer />
            </ApplicationLayout>
        </IntlProvider>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    loadGeoBooked: () => dispatch(fetchGeoBooked),
});

const mapStateToProps = (state: RootState) => ({
    accountId: getUserAccount(state),
    displayMessages: getDisplayMessages(state),
    userLocale: getLocale(state),
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
