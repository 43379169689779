import { fetchData } from '../../configuration/setup/fetch';
import { mapRoutes } from './mappers/mapRoutes';
import { routesActions } from '../../application/redux/routes/routesReducer';
import { enrichWaypointsWithCoordinates } from './fetchWaypointCoordinates';
import {
    getAvoidMotorway,
    getAvoidToll,
    getCalculateAlternatives,
    getConsiderTraffic,
    getCurrency,
    getDepartureDate,
    getDepartureTime,
    getIgnoreRestrictionsAroundWaypoints,
    getVehicleAttributes,
    getVehicleCostsPerKilometer,
} from '../../application/redux/options/optionsSelectors';
import { clearRoute } from '../livemonitorCommunication/propagateRoutesToParent';
import { createRouteQuery } from './createRouteQuery';
import { getUserAccount } from '../../configuration';
import { datadogLogs } from '@datadog/browser-logs';
import { format } from 'date-fns';
import { config } from '../../config';

const HERE_ROUTE_ENDPOINT_BASEURL = 'https://routematching.hereapi.com';

export const fetchRoutes = async (dispatch: Function, getState: Function) => {
    try {
        dispatch(routesActions.fetchRoutesTriggered());
        clearRoute();

        const orderedWaypointsWithCoordinates = await enrichWaypointsWithCoordinates(dispatch, getState);
        const hereApiKey = config.hereApiKey;

        const parameters = {
            // departureTime: format(fromUnixTime(Date.now() / 1000), "yyyy-MM-dd'T'HH:mm:ss"),
            departureTime: getDepartureTime(getState()),
            departureDate: format(getDepartureDate(getState()), 'yyyy-MM-dd'),
            waypoints: orderedWaypointsWithCoordinates,
            vehicleAttributes: getVehicleAttributes(getState()),
            considerTraffic: getConsiderTraffic(getState()),
            avoidMotorway: getAvoidMotorway(getState()),
            avoidToll: getAvoidToll(getState()),
            ignoreRestrictionsAroundWaypoints: getIgnoreRestrictionsAroundWaypoints(getState()),
            calculateAlternatives: getCalculateAlternatives(getState()),
            currency: getCurrency(getState()),
            vehicleCostsPerKilometer: getVehicleCostsPerKilometer(getState()),
        };

        const queryParams = createRouteQuery(parameters, hereApiKey);

        const hereResponse = await fetchData(`${HERE_ROUTE_ENDPOINT_BASEURL}${queryParams}`);
        datadogLogs.logger.info(
            `Called calculateroute from here for the following rio account: ${getUserAccount(getState())}`
        );

        dispatch(routesActions.suggestedRoutesChanged(mapRoutes(hereResponse, orderedWaypointsWithCoordinates)));
    } catch (error: any) {
        dispatch(routesActions.fetchRouteSuggestionsFailed(error));
    }
};
