import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';

import { fetchData } from '../../configuration/setup/fetch';
import { getLocale } from '../../configuration';
import { Coordinates } from '../../application/redux/search/searchReducer';
import { RootState } from '../../configuration/setup/store';
import { config } from '../../config';

const REVERSE_GEOCODING_ENDPOINT = 'https://revgeocode.search.hereapi.com/v1/revgeocode';

interface ReverseGeocodeOptions {
    prox: string;
    language: string;
    apikey: string;
}

const reverseGeocode = async (options: ReverseGeocodeOptions): Promise<string> => {
    const { apikey, prox: rawProx, language } = options;
    const prox = rawProx.replace(',', '%2C');
    const url = `${REVERSE_GEOCODING_ENDPOINT}?apiKey=${apikey}&at=${prox}&types=address&limit=1&lang=${language}`;

    const response = await fetchData(url);

    const firstResult = get('items[0]')(response);

    if (firstResult) {
        return firstResult.address.label;
    } else {
        throw new Error('No corresponding address for the given coordinates!');
    }
};

export const resolveAddress = async (coordinates: Coordinates, getState: () => RootState): Promise<string | null> => {
    if (isNil(coordinates) || isNil(coordinates.lat) || isNil(coordinates.lng)) {
        return null;
    }

    const hereApiKey = config.hereApiKey;
    const language = getLocale(getState());
    const prox = `${coordinates.lat},${coordinates.lng}`;

    try {
        return await reverseGeocode({ apikey: hereApiKey, prox, language });
    } catch (e) {
        console.error('Error while resolving address', e);
        return null;
    }
};
